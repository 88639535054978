@import url('//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css');

html {
  background-color: #fbfbfb;
}

html {
  font-size: 16px;
  /* 기본 설정 */
}

@media (max-width: 768px) {
  html {
    font-size: 16px;
    /* 태블릿 이하 */
  }
}

@media (max-width: 480px) {
  html {
    font-size: 16px;
    /* 모바일 이하 */
  }
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', 'SpoqaHanSans' !important;
  background-color: #fbfbfb !important;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
}

::-webkit-scrollbar {
  position: absolute;
  width: 0px;
}

textarea:focus,
input:focus {
  outline: none;
}

/* App.css */
.location-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.location-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.location-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 500ms,
    transform 500ms;
}

.location-exit {
  opacity: 1;
  transform: translateY(0);
}

.location-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.3;
}

.page-container {
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .page-container {
    max-width: 100%;
    /* padding: 0 20px; */
  }
}

@media (max-width: 480px) {
  .page-container {
    /* padding: 0 17px; */
  }
}

.tab-item {}

/* =[Map]=============================================================================================== */
.map-outer {
  flex: 1;
  border-radius: 50px;
  background-color: #6f5e53;
  color: #fff;
  vertical-align: middle;
  /* padding: 10px 15px; */
  z-index: 0 !important;
  padding: 0 20px;
}

.map-room {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #6f5e53;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  padding: 10px;
  padding-right: 20px;
  pointer-events: auto;
  z-index: 100000;
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  padding: 10px 30px;
}

.map-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  /* margin-right: 5px; */
  border-radius: 30px;
  background-color: #6f5e53;
  position: relative;
}

.map-inner {
  z-index: 0 !important;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Pretendard';
  white-space: nowrap;
}

.map-price {
  text-align: center;
  color: var(--Grayscale-0, #fff);
  text-align: center;

  /* 12 bold eng */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 18px */
  letter-spacing: -0.03px;
}

.map-image {
  position: relative;
  top: 4px;
  left: 4px;
  width: 20px;
  z-index: 0 !important;
}

.color_light_brown_map {
  color: #83572c;
  padding: 8px;
  border: 2px solid #8b5422;
  border-radius: 10px;
}

.map_property_icons {
  margin-left: 10px;
}

.map_property_icons>div {
  /* display: block; */
  margin-right: 10px;
  float: left;
  /* position: relative; */
  /* text-align: justify; */
  /* width: 50; */
}

.css-1tape97 {
  border-right: 0px solid transparent !important;
}

/* =[Map]=============================================================================================== */

@media (max-width: 480px) {
  .css-1tape97 {
    border-right: 0px solid transparent !important;
  }
}

.MuiAccordion-root {
  box-shadow: none !important;
}

.MuiAccordionSummary-root {
  border-bottom: 1px solid #e4e4e4 !important;
}

/* [InputField] ======================================================================================= */
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
  background: var(--Grayscale-5, #f8f8f8);
}

/* [Accordion] ======================================================================================= */
.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  border-top: none;
}

.MuiPaper-root.MuiAccordion-root.Mui-expanded {
  /* margin: 16px 0 !important; */
  margin: 0 !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 16px 0 !important;
}

.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.css-1086bdv-MuiPaper-root-MuiAccordion-root {
  margin-bottom: 0 !important;
}

#ModalContentStyleBox>div {
  height: auto;
}

[class^='Launcherstyled__LauncherButtonWrapper-ch-front'] {
  display: none !important;
}

:root {
  --launcher-display: none;
}

/* 인원설정 모달 배경 모바일에서 투명하게 설정 */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  @media screen and (max-width: 480px) {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.MuiPopover-paper {
  box-shadow: none !important;
  background-color: transparent !important;
}

ol {
  margin: 10px 20px;
}

ul {
  list-style-type: disc;
}

/* [InputField] ======================================================================================= */
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
  border-radius: 8px !important;
}